import { DrillType, Track } from './constants'
import type { DrillDefinition, Score } from './drillTypes'

export const defaultNotation = `X:1
M:4/4
K:C
L:1/8
Q:1/4=60
V:1 clef=treble
K:C
"C"CDEF|
V:2 clef=bass
K:C
C,,D,,E,,F,,|
`

export const defaultScore: Score = {
  baseNotation: defaultNotation,
  playedNotation: defaultNotation,
  progression: 'none',
}

export const defaultDrill: DrillDefinition = {
  type: DrillType.playTheScore,
  track: Track.general,
  programId: 2,
  chapter: 999999,
  notes: '',
  tagIds: [],
  genreId: 1,
  title: '',
  instructions: '',
  articles: [],
  links: '',
  score: defaultScore,
}
